import React from 'react';
import { Navigation } from '../ReactCalendar';
import {
  getFormattedDateNames,
  getNiceDate,
  nextWeek,
  prevWeek,
} from './utils';
// @ts-ignore
import { ArrowLeftLargeIcon, ArrowRightLargeIcon, CalendarIcon } from 'assets';
import { Flex, Link, Text, Box } from 'ui';
import Grid from 'components/layout/Grid';
import { navBarStyles } from './styles';
import { CalendarNavigationProps, NavType, TopDateType } from './types';
import Icon from 'components/Icon';
import { substituteWeekNumberInFormattedString } from 'utils';

export default function CalendarNavigation({
  value,
  height,
  onDateSelect,
  onNavClick,
  withCalendarIcon = true,
  weekSelect,
  topDateType,
  position = 'bottom',
  disableNext,
  disablePrev,
  navType,
}: CalendarNavigationProps) {
  const { curWeek, year } = getFormattedDateNames(value);

  return (
    <Grid
      css={[navBarStyles(position)]}
      gridAutoFlow="column"
      justifyContent="space-around"
      gap={position === 'bottom' ? '20px' : '0px'}
    >
      {/* <Link
        kind="icon"
        onClick={() => {
          if (disablePrev) return;
          onDateSelect(prevWeek(value));
        }}
      >
        <Icon ml="3px" pathFillColor="#569DE5">
          <ArrowLeftLargeIcon />
        </Icon>
      </Link> */}
      <Flex
        alignItems={'center'}
        onClick={() => {
          if (disablePrev) return;
          onDateSelect(prevWeek(value));
        }}
      >
        <Icon ml="3px" pathFillColor="#569DE5">
          <ArrowLeftLargeIcon />
        </Icon>
      </Flex>
      <Flex alignItems="center" onClick={onNavClick}>
        <Navigation
          // @ts-ignore
          navigationLabel={() => {
            return (
              <Flex alignItems={'center'} height={height}>
                <Text
                  mt="2px"
                  fontSize={navType === NavType.Ribbon ? '14px' : '16px'}
                  lineHeight={'14px'}
                >
                  {substituteWeekNumberInFormattedString(
                    weekSelect || topDateType === TopDateType.Week
                      ? `Week ${curWeek}, ${year}`
                      : `${getNiceDate(value)}, ${year}`
                  )}
                </Text>
                {withCalendarIcon && (
                  <Text ml="10px" display="flex">
                    <CalendarIcon />
                  </Text>
                )}
              </Flex>
            );
          }}
          nextLabel={null}
          prevLabel={null}
          key={`${Math.random()}-${value.valueOf()}`}
          next2Label={null}
          prev2Label={null}
          activeStartDate={value}
          drillUp={() => null}
          view="month"
          views={['month']}
          setActiveStartDate={onDateSelect}
        />
      </Flex>
      <Flex
        alignItems={'center'}
        onClick={() => {
          if (disableNext) return;
          onDateSelect(nextWeek(value));
        }}
      >
        <Icon pathFillColor="#569DE5">
          <ArrowRightLargeIcon />
        </Icon>
      </Flex>
    </Grid>
  );
}
