import 'styled-components';
import { Flex, Row } from 'ui';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import RecordButton from './RecordButton';
import TopTextBlock from '@/components/TopTextBlock';
import { usePostAnswersConfirmation } from '@/api/services';
import { routes } from '@/constants';
import Preloader from '@/components/Preloader';
import { isLocal } from '@/utils';
import * as Sentry from '@sentry/react';
import { TURN_OFF_HANDLE_ROUTING } from '@/contexts/QuestionsAnswersContext/constants';
import useGetQuestionWithAnswered from '@/hooks/useGetQuestionWithAnswered';
import useCountdown from '@/hooks/useTimer';
import { VIDEO_MAX_LENGTH } from './constants';
import theme from 'theme';
import VideoReplay from './VideoReplay';
import VideoRecord from './VideoRecord';

export default function Answers() {
  const [showRetryConfirm, setShowRetryConfirm] = useState(false);
  const navigate = useNavigate();

  const {
    questionName,
    isEng,
    invitationKey,
    isLoading,
    data,
    selectedLanguage,
    questionNumber,
    totalQuestions,
    questionTopics,
    onNextQuestion,
  } = useGetQuestionWithAnswered();

  const { mutateAsync } = usePostAnswersConfirmation();
  const [isRecordUploading, setIsRecordUploading] = useState(false);

  const isLastQuestion = questionNumber >= totalQuestions - 1;
  const answerPresignedUrl = data?.answer_presigned_url;

  const isIntroQuestion = questionNumber === 0;

  const [recordReplaySrc, setRecordReplaySrc] = useState<string | null>(null);
  const breakpointSm = Number(theme.breakpoints.sm);
  const cameraWidth =
    window.innerWidth > breakpointSm ? breakpointSm : window.innerWidth;

  const timer = useCountdown({
    initTime: VIDEO_MAX_LENGTH,
    onTimeout: () => {
      handleStopRecord();
    },
  });

  const mediaRecordRef = useRef<MediaRecorder | null>(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isRecordReady, setIsRecordReady] = useState(false);
  const [videoRecordDataWebm, setVideoRecordDataWebm] = useState<Blob>();

  const isShowRecord = isRecording || !isRecordReady;

  const handleStartRecord = useCallback(async () => {
    if (!mediaRecordRef.current) {
      alert('Video camera is not available. Please provide permissions');
      return null;
    }

    if (mediaRecordRef.current.state === 'inactive') {
      mediaRecordRef.current.start();
      setIsRecording(true);
      timer.reset();
      timer.startCountdown();
    }
  }, [timer]);

  const handleStopRecord = useCallback(() => {
    if (!mediaRecordRef.current) {
      return null;
    }
    mediaRecordRef.current.stop();
    setIsRecording(false);
    timer.stop();
  }, [timer]);

  const handleSubmitAnswerToPresinedUrl = async () => {
    if (!videoRecordDataWebm || !answerPresignedUrl) {
      console.error('No video record data or answer presigned url');
      Sentry.captureException(
        new Error('No video record data or answer presigned url')
      );

      return;
    }

    setIsRecordUploading(true);

    try {
      if (!isLocal) {
        await fetch(answerPresignedUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'video/webm',
          },
          body: videoRecordDataWebm,
        })
          .then((res) => {
            if (!res.ok || res.status !== 200) {
              Sentry.captureException(res);
            }
          })
          .catch((e) => {
            console.error('Failed to upload video to presigned url', e);
            Sentry.captureException(e);
          });
      }

      await mutateAsync({
        invitation_key: invitationKey,
        question_num: questionNumber,
        language: selectedLanguage,
      }).catch((e) => {
        console.error('Failed to post answer confirmation ', e);
        Sentry.captureException(e);
      });
    } catch (e) {
      alert('Server connection error. Please try again later');
      console.error('Failed to upload video to presigned url', e);
      Sentry.captureException(e);
    } finally {
      setIsRecordUploading(false);
    }
  };

  const handleNextStep = async () => {
    if (!isRecordReady) {
      return;
    }

    await handleSubmitAnswerToPresinedUrl()
      .catch((e) => {
        console.error('Failed to submit answer', e);
        Sentry.captureException(e);
        alert('Failed to submit answer. Please try again later');
      })
      .then(() => {
        if (isLastQuestion) {
          navigate(`${routes.finish}?${TURN_OFF_HANDLE_ROUTING}=1`);
          return;
        }

        onNextQuestion();
        navigate(routes.questions);
      });
  };

  return (
    <LayoutPageMobile
      header={
        <Row flexCenter>
          {isIntroQuestion ? (
            <TopTextBlock
              title={isEng ? 'Topics' : 'Temas'}
              content={questionTopics}
            />
          ) : (
            <TopTextBlock
              title={`${
                isEng ? 'Topics for Question' : 'Temas para la Pregunta'
              } #${questionName}`}
              content={questionTopics}
            />
          )}
        </Row>
      }
      loading={isLoading}
    >
      <Row
        flexCenter
        position="relative"
        flexGrow={1}
        zIndex="50"
        height="100%"
        alignContent="flex-end"
      >
        {!isShowRecord ? (
          <VideoReplay
            src={recordReplaySrc}
            width={cameraWidth}
            isEng={isEng}
          />
        ) : (
          <VideoRecord
            cameraWidth={cameraWidth}
            isEng={isEng}
            mediaRecordRef={mediaRecordRef}
            setIsRecordReady={setIsRecordReady}
            setRecordReplaySrc={setRecordReplaySrc}
            setVideoRecordDataWebm={setVideoRecordDataWebm}
          />
        )}
      </Row>
      <Row flexCenter>
        <RecordButton
          isEng={isEng}
          onShowRetryConfirm={() => setShowRetryConfirm(true)}
          isShowRetryConfirm={showRetryConfirm}
          isRecordReady={isRecordReady}
          isRecording={isRecording}
          onNextStep={handleNextStep}
          onRecordStart={handleStartRecord}
          onRecordStop={handleStopRecord}
          onRetryRecord={() => {
            setIsRecordReady(false);
          }}
          onHideRetryConfirm={() => setShowRetryConfirm(false)}
          timeLeft={!isRecordReady ? timer.time : 0}
        >
          {isRecordUploading && (
            <Flex gap="10px" flexCenter>
              <span>Uploading...</span> <Preloader size="25px" />
            </Flex>
          )}
          {!isRecordUploading &&
            !isLastQuestion &&
            (isEng ? 'SUBMIT AND CONTINUE' : 'ENVIAR Y CONTINUAR')}
          {!isRecordUploading &&
            isLastQuestion &&
            (isEng ? 'SUBMIT AND FINISH' : 'ENVIAR Y TERMINAR')}
        </RecordButton>
      </Row>
    </LayoutPageMobile>
  );
}
