import { isProd, isStage } from '@/utils';

type FetchOptions<Params = unknown> = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: Record<string, string>;
  body?: Params;
  params?: Record<string, string | number>;
  timeout?: number; // Timeout in milliseconds
} & Omit<RequestInit, 'method' | 'headers' | 'body'>;

type FetchResponse<T> = T;

const stageBaseApiUrl = 'https://stage.123mgr.com/interview/api';
const prodBaseApiUrl = 'https://123mgr.com/interview/api';

console.info({
  isProd,
  isStage,
  stageBaseApiUrl,
  prodBaseApiUrl,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hostname: window.location.hostname,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  __VITE_INTERVIEW_API_URL__: window.__VITE_INTERVIEW_API_URL__,
});

const apiUrl = isProd ? prodBaseApiUrl : stageBaseApiUrl;

export const fetchWrapper = async <Response = unknown, Params = unknown>(
  url: string,
  options: FetchOptions<Params> = {}
): Promise<FetchResponse<Response>> => {
  const urlParams = new URLSearchParams(window.location.search);
  const debug = !!urlParams.get('debug');
  const {
    method = 'GET',
    body,
    headers,
    params,
    timeout = 10000,
    ...rest
  } = options;

  const query = params
    ? `?${new URLSearchParams(params as Record<string, string>).toString()}`
    : '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // const fullUrl = window.__VITE_INTERVIEW_API_URL__ + `${url}${query}`;
  const fullUrl = apiUrl + `${url}${query}`;

  const controller = new AbortController();
  const id = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    const response = await fetch(fullUrl, {
      method,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
      signal: controller.signal,
      ...rest,
    });

    clearTimeout(id);

    if (!response.ok) {
      // if BE return just text intead of json
      const errorText: string | null = await response
        .text()
        .then((text: string) => text?.slice(0, 300))
        .catch(() => {
          console.error(
            'Error while parsing response text from fetchWrapper error'
          );
          return null;
        });

      if (errorText) {
        console.error('Response error: ', errorText);
        throw new Error(errorText);
      }

      const error = await response.json().catch(() => {
        console.error(
          'Error while parsing response json from fetchWrapper error'
        );
        return null;
      });

      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        throw new Error(error.message);
      }

      if (response.status) {
        throw new Error(`Error ${response.status.toString()}`);
      }
    }

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    return (await response.json().catch(() => {
      console.error('Error while parsing response json from fetchWrapper body');
      return null;
    })) as Response;
  } catch (error: unknown) {
    console.error('Fetch error', error);

    if (debug) {
      alert(error);
    }

    clearTimeout(id);
    if (
      error &&
      typeof error === 'object' &&
      'name' in error &&
      error.name === 'AbortError'
    ) {
      throw new Error('Request timeout');
    }
    throw error;
  }
};

export const get = async <T = unknown>(
  url: string,
  options?: FetchOptions
): Promise<FetchResponse<T>> => {
  return fetchWrapper(url, { ...options, method: 'GET' });
};

export const post = async <Response = unknown, Params = unknown>(
  url: string,
  body: Params,
  options?: FetchOptions<Params>
): Promise<FetchResponse<Response>> => {
  return fetchWrapper<Response, Params>(url, {
    body,
    ...options,
    method: 'POST',
  });
};

export const put = async <T = unknown>(
  url: string,
  options?: FetchOptions
): Promise<FetchResponse<T>> => {
  return fetchWrapper(url, { ...options, method: 'PUT' });
};

export const del = async <T = unknown>(
  url: string,
  options?: FetchOptions
): Promise<FetchResponse<T>> => {
  return fetchWrapper(url, { ...options, method: 'DELETE' });
};
