import { useState } from 'react';
import {
  CUR_QUESTION_NUM,
  initValues,
  INVITATION_KEY,
  SELECTED_LANGUAGE,
} from './constants';
import { QuestionsAnswersContextType } from './types';
import { Language } from '@/api/typesApi';

export const useQuestionsAnswersLogic = (
  values: Partial<QuestionsAnswersContextType> = {}
): QuestionsAnswersContextType => {
  const actualValues = { ...initValues, ...values };

  /// Set current question number
  const prevSavedQuestionNumber = Number(
    sessionStorage.getItem(CUR_QUESTION_NUM)
  );
  const [questionNumber, setQuestionNumberFn] = useState<number>(
    prevSavedQuestionNumber > 0
      ? prevSavedQuestionNumber
      : actualValues.questionNumber
  );

  const setQuestionNumber = (num: number) => {
    console.info('setQuestionNumber', num);
    sessionStorage.setItem(CUR_QUESTION_NUM, num.toString());
    setQuestionNumberFn(num);
  };

  /// Set total questions
  const [totalQuestions, setTotalQuestions] = useState<number>(
    actualValues.totalQuestions
  );

  /// Set selected language
  const preSavedSelectedLanguage = localStorage.getItem(
    SELECTED_LANGUAGE
  ) as Language;
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    preSavedSelectedLanguage ?? actualValues.selectedLanguage
  );

  /// Set invitation key
  const preSavedInvitationKey = localStorage.getItem(INVITATION_KEY);
  const [invitationKey, setInvitationKey] = useState<string>(
    preSavedInvitationKey || ''
  );

  const onReset = () => {
    setQuestionNumber(0);
    setTotalQuestions(3);
    setSelectedLanguage('english');
  };

  const onSetLanguage = (language: Language) => {
    localStorage.setItem(SELECTED_LANGUAGE, language);
    setSelectedLanguage(language);
  };

  const onSetTotalQuestions = (total: number) => {
    setTotalQuestions(total);
  };

  const onSetInvitationKey = (key: string) => {
    if (key !== preSavedInvitationKey) {
      onReset();
    }

    localStorage.setItem(INVITATION_KEY, key);
    setInvitationKey(key);
  };

  const onNextQuestion = () => {
    const newQuestionNumber = questionNumber + 1;

    console.info('newQuestionNumber', newQuestionNumber);
    console.info('totalQuestions', totalQuestions);

    if (newQuestionNumber > totalQuestions - 1) return;

    sessionStorage.setItem(CUR_QUESTION_NUM, newQuestionNumber.toString());
    setQuestionNumber(newQuestionNumber);
  };

  console.table({
    questionNumber,
    totalQuestions,
    selectedLanguage,
    invitationKey,
  });

  return {
    questionNumber,
    totalQuestions,
    onNextQuestion,
    selectedLanguage,
    onSetLanguage,
    onSetTotalQuestions,
    invitationKey,
    setQuestionNumber,
    onSetInvitationKey,
  };
};
