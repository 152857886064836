import { createContext } from 'react';
import { QuestionsAnswersContextType } from './types';

export const initValues: QuestionsAnswersContextType = {
  questionNumber: 0,
  totalQuestions: 3,
  onNextQuestion: () => {},
  selectedLanguage: 'english',
  onSetLanguage: () => {},
  onSetTotalQuestions: () => {},
  invitationKey: '',
  onSetInvitationKey: () => {},
  setQuestionNumber: () => {},
};

export const questionsAnswersContext = createContext<
  QuestionsAnswersContextType | undefined
>(undefined);

export const SELECTED_LANGUAGE = 'selectedLanguage';
export const CUR_QUESTION_NUM = 'currentQuestionNum';
export const INVITATION_KEY = 'invitation_key';
export const DEBUG = 'debug';
export const TURN_OFF_HANDLE_ROUTING = 'turn_off_handle_routing';
