import React from 'react';
import PropTypes from 'prop-types';
import {
  getYear,
  getMonth,
  getDate,
  getDaysInMonth,
} from '@wojtekmaj/date-utils';

import WeekNumber from './WeekNumber';
import Flex from '../Flex';
import {weekNumIncrease} from 'utils'


import { getBeginOfWeek, getDayOfWeek, getWeekNumber } from '../shared/dates';
import { isCalendarType } from '../shared/propTypes';

export default function WeekNumbers(props) {
  const {
    activeStartDate,
    calendarType,
    onClickWeekNumber,
    onMouseLeave,
    showFixedNumberOfWeeks,
    customWeekStartDay,
    customWeeksOffset,
  } = props;

  const numberOfWeeks = (() => {
    if (showFixedNumberOfWeeks) {
      return 6;
    }

    const numberOfDays = getDaysInMonth(activeStartDate);
    const startWeekday = getDayOfWeek(
      activeStartDate,
      calendarType,
      customWeekStartDay
    );

    const days = numberOfDays - (7 - startWeekday);
    return 1 + Math.ceil(days / 7);
  })();

  const dates = (() => {
    const year = getYear(activeStartDate);
    const monthIndex = getMonth(activeStartDate);
    const day = getDate(activeStartDate);

    const result = [];

    for (let index = 0; index < numberOfWeeks; index += 1) {
      result.push(
        getBeginOfWeek(
          new Date(year, monthIndex, day + index * 7),
          calendarType,
          customWeekStartDay
        )
      );
    }

    return result;
  })();

  const weekNumbers = dates.map((date) => {
   let curWeek = getWeekNumber(
      date,
      calendarType,
      customWeekStartDay,
      customWeeksOffset
    )

    return weekNumIncrease({weekNumber: curWeek, curYear: getYear(date)}) 
  });


  return (
    <Flex
      className="react-calendar__month-view__weekNumbers"
      count={numberOfWeeks}
      direction="column"
      onFocus={onMouseLeave}
      onMouseOver={onMouseLeave}
      style={{ flexBasis: 'calc(100% * (1 / 8)', flexShrink: 0 }}
    >
      {weekNumbers.map((weekNumber, weekIndex) => (
        <WeekNumber
          key={weekNumber}
          date={dates[weekIndex]}
          onClickWeekNumber={onClickWeekNumber}
          weekNumber={weekNumber}
        />
      ))}
    </Flex>
  );
}

WeekNumbers.propTypes = {
  customWeekStartDay: PropTypes.number,
  customWeeksOffset: PropTypes.number,
  activeStartDate: PropTypes.instanceOf(Date).isRequired,
  calendarType: isCalendarType.isRequired,
  onClickWeekNumber: PropTypes.func,
  onMouseLeave: PropTypes.func,
  showFixedNumberOfWeeks: PropTypes.bool,
};
