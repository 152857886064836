import { Flex } from 'ui';
import Preloader from '../Preloader';
import colors from 'theme/colors';

export default function PreloaderFullPage() {
  return (
    <Flex
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      justifyContent="center"
      alignItems="center"
      backgroundColor={colors.whitesmoke}
      zIndex="1000"
    >
      <Preloader />
    </Flex>
  );
}
