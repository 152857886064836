import { post } from './fetchWrapper';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  InterviewPreviewOutput,
  QuestionsAnswersInput,
  QuestionsOutput,
  StatusInput,
  StatusOutput,
} from './typesApi';
import { endpoints } from '@/constants';
import { mockApi } from './mockApi';
import { isLocal } from '@/utils';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState } from 'react';

export const api = isLocal
  ? mockApi
  : {
      getStatus: (input: StatusInput) =>
        post<StatusOutput, StatusInput>(endpoints.status, input),
      getQuestions: (input: QuestionsAnswersInput) =>
        post<QuestionsOutput, QuestionsAnswersInput>(
          endpoints.questions,
          input
        ),
      postAnswersConfirmation: (input: QuestionsAnswersInput) =>
        post<boolean, QuestionsAnswersInput>(
          endpoints.answersConfirmation,
          input
        ),
      postInterviewPreview: (input: StatusInput) =>
        post<InterviewPreviewOutput, StatusInput>(
          endpoints.interviewPreview,
          input
        ),
    };

export const useGetStatus = (invitationKey: string) => {
  const [loading, setLoading] = useState(true);
  const debounced = useDebouncedCallback(async (invitation_key: string) => {
    return await mutateAsync({ invitation_key });
  }, 500);

  useEffect(() => {
    if (invitationKey) {
      setLoading(true);
      debounced(invitationKey);
    }
  }, [debounced, invitationKey]);

  const { mutateAsync, data, isLoading, isError, error } = useMutation({
    mutationKey: ['status'],
    mutationFn: async (input: StatusInput) => {
      const resp = await api.getStatus(input);
      return resp;
    },
    onSuccess: () => {
      setLoading(false);
    },
  });

  return { data, isLoading: loading || isLoading, isError, error };
};

export const useGetQuestions = (input: QuestionsAnswersInput) => {
  const debounced = useDebouncedCallback((input: QuestionsAnswersInput) => {
    return mutateAsync(input);
  }, 500);

  useEffect(() => {
    if (input) {
      debounced(input);
    }
  }, [debounced, input]);

  const { mutateAsync, data, isLoading, isError, error } = useMutation({
    mutationKey: ['questions'],
    mutationFn: async (input: QuestionsAnswersInput) => {
      return await api.getQuestions(input);
    },
    useErrorBoundary: false,
  });

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export const useGetQuestionsMutation = ({ onError, onSuccess }) => {
  return useMutation({
    mutationKey: ['questions'],
    mutationFn: async (input: QuestionsAnswersInput) => {
      const resp = await api.getQuestions(input);
      return resp;
    },
    onError,
    onSuccess,
  });
};

export const usePostAnswersConfirmation = () => {
  return useMutation({
    mutationFn: async (input: QuestionsAnswersInput) => {
      const resp = await api.postAnswersConfirmation(input);
      return resp;
    },
  });
};

export const useInterviewPreview = (input: StatusInput) => {
  return useQuery({
    enabled: !!input?.invitation_key,
    queryKey: ['interviewPreview'],
    queryFn: async () => {
      const resp = await api.postInterviewPreview(input);
      return resp;
    },
  });
};
