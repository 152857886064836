import PageContainer from 'components/layout/PageContainer';
import { PageContainerProps } from 'components/layout/PageContainer/PageContainer';
import colors from 'theme/colors';
import { BoxProps, Button } from 'ui';
import { css } from 'styled-components';
import PreloaderFullPage from '../PreloaderFullPage';
import ContainerFullPage from '../ContainerFullPage';
import { useNavigate } from 'react-router';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';

interface LayoutPageMobileProps extends PageContainerProps {
  rootBoxProps?: Partial<BoxProps>;
  children?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  errorInfo?: string;
}

export default function LayoutPageMobile({
  children,
  header,
  footer,
  loading,
  error,
  errorInfo,
  ...restProps
}: LayoutPageMobileProps) {
  const { setQuestionNumber } = useQuestionsAnswers();
  const navigate = useNavigate();
  const isLoading = loading && !error;
  return (
    <PageContainer
      py="0"
      pt="20px"
      overflow="hidden"
      height="100%"
      position="relative"
      minHeight="100dvh"
      css={css`
        & {
          min-height: 100vh;
          min-height: 100dvh;
        }
      `}
      width="100%"
      backgroundColor={colors.whitesmoke}
      display="grid"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gridTemplateRows={`${header ? 'max-content' : ''} 1fr ${
        footer ? 'max-content' : ''
      }`}
      {...restProps}
      {...(isLoading && { pt: '0 !important' })}
    >
      {isLoading && <PreloaderFullPage />}
      <>
        {header}
        {children}
        {footer}
      </>
      {error && (
        <ContainerFullPage>
          {errorInfo ?? 'Something went wrong. Please try again later'}
          <Button
            variant="primary"
            size="lg"
            mt="20px"
            onClick={() => {
              setQuestionNumber(0);
              sessionStorage.clear();
              navigate('/');
            }}
          >
            Restart
          </Button>
        </ContainerFullPage>
      )}
    </PageContainer>
  );
}
