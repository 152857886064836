import 'styled-components';
import { Box, Flex, Row, Text } from 'ui';
import { default as WatchSvg } from './assets/watch.svg?react';
import { useNavigate } from 'react-router';
import useVideoControl from '@/hooks/useVideoControl';
import VideoPlayer from '@/components/VideoPlayer';
import ButtonFillEffect from '@/components/ButtonFillEffect';
import LayoutPageMobile from '@/components/LayoutPageMobile';
import { useGetStatus } from '@/api/services';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import { css } from 'styled-components';
import { isLocal } from '@/utils';

export default function Tutorial() {
  const { isWatched, curRef, setCurRef } = useVideoControl();
  const navigate = useNavigate();
  const { selectedLanguage, invitationKey } = useQuestionsAnswers();
  const { data, isLoading } = useGetStatus(invitationKey);
  const isEngSelected = selectedLanguage === 'english';
  const curVideoUrl = isEngSelected ? data?.english_intro : data?.spanish_intro;
  const name = data?.lead_name;

  let totalQuestions = isEngSelected
    ? data?.questions_total_english
    : data?.questions_total_spanish;
  totalQuestions = totalQuestions || 0;

  const handleNextStep = () => {
    navigate('/questions');
  };

  return (
    <LayoutPageMobile
      loading={isLoading}
      header={
        <Box dxFlex justifyContent="center" alignItems="center" gap="10px">
          <Text fontWeight="900" mt="" fontSize="20px" upper>
            {isEngSelected ? 'Hello' : 'Hola'}, {name}!
          </Text>
        </Box>
      }
      footer={
        <Row
          py="20px"
          borderTopLeftRadius="4px"
          borderTopRightRadius="4px"
          width="100%"
          flexCenter
          backgroundColor="transparent"
        >
          <ButtonFillEffect
            onClick={isLocal || isWatched ? handleNextStep : undefined}
            videoRef={curRef}
          >
            {isEngSelected ? 'Start Interview' : 'INICIAR ENTREVISTA'}
          </ButtonFillEffect>
        </Row>
      }
    >
      <Row flexCenter>
        <Row mt="20px">
          <Flex
            backgroundColor={'#f1f5ff'}
            width="330px"
            borderRadius="4px"
            flexDirection="column"
            padding="10px 20px"
            fontWeight="900"
            css={css`
              & {
                text-transform: uppercase;
              }
            `}
          >
            <Flex justifyContent="center" alignItems="center" gap="20px">
              <Flex>
                <WatchSvg />
              </Flex>
              <Flex
                css={css`
                  & {
                    font-size: 16px;
                    letter-spacing: -1px;
                    text-transform: uppercase;
                  }
                `}
              >
                {isEngSelected
                  ? 'Please watch tutorial video before you start'
                  : 'Por favor mira el video tutorial antes de empezar'}
              </Flex>
            </Flex>
          </Flex>
        </Row>
        <Row mt="20px">
          <VideoPlayer autoPlay onSetCurRef={setCurRef} src={curVideoUrl} />
        </Row>
        <Row mt="20px">
          <Flex
            backgroundColor={'#f1f5ff'}
            width="330px"
            borderRadius="4px"
            flexDirection="column"
            padding="10px 20px"
            fontSize="18px"
            fontWeight="700"
            css={css`
              & {
                text-transform: uppercase;
              }
            `}
          >
            <Flex>
              {isEngSelected ? 'Total Questions' : 'Total de Preguntas'}:{' '}
              {totalQuestions}
            </Flex>
            <Flex>
              {isEngSelected ? 'Estimated time' : 'Tiempo estimado'}
              {': '}
              {totalQuestions * 2} min
            </Flex>
          </Flex>
        </Row>
      </Row>
    </LayoutPageMobile>
  );
}
