export const isLocal = process.env.NODE_ENV === 'development';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isProd = window.location.hostname.match(/interview.123jobs.com/);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isStage = window.location.hostname.match(
  /interview-stage.123jobs.com/
);
