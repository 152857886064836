import { FlexProps } from 'components/layout/Flex/Flex.types';
import { css } from 'styled-components';
import { Flex } from 'ui';

export interface ContainerFullPageProps extends FlexProps {
  children: React.ReactNode;
}

export default function ContainerFullPage({
  children,
  ...restProps
}: ContainerFullPageProps) {
  return (
    <Flex
      p="20px"
      width="100%"
      textAlign="center"
      flexCenter
      flexDirection="column"
      overflow="hidden"
      fontSize="20px"
      fontWeight="800"
      css={css`
        & {
          min-height: 100vh;
          min-height: 100dvh;
        }
      `}
      {...restProps}
    >
      {children}
    </Flex>
  );
}
