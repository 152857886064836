import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './containers/App/App.tsx';
import theme, {
  antdColorsConfig,
  antdTypographyConfig,
} from '../../frontendReact/src/theme';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import AppContext from '../../frontendReact/src/containers/App/AppContext.tsx';
import { QuestionsAnswersProvider } from './contexts/QuestionsAnswersContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './components/ErrorBoundary';
import { isLocal, isProd } from './utils/index.ts';

window['WEEKS_OFFSET'] = 0;
window['FIRST_DAY_OFFSET'] = 1;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const sentryStageDsn =
  'https://f7787f135bb139ccd6185e3df0d5feb5@o4508333282754560.ingest.us.sentry.io/4508339869122560';

const sentryProdDsn =
  'https://3707149d75874b944d57e967da3fbec3@o4508333282754560.ingest.us.sentry.io/4508339867615232';

Sentry.init({
  dsn: isProd ? sentryProdDsn : sentryStageDsn,
  integrations: [],
  enabled: !isLocal,
  beforeSend(event) {
    /// Send localStorage and sessionStorage data to Sentry
    const localStorageData = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        localStorageData[key] = localStorage.getItem(key);
      }
    }

    const sessionStorageData = {};
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key) {
        sessionStorageData[key] = sessionStorage.getItem(key);
      }
    }

    event.extra = {
      ...event.extra,
      localStorage: localStorageData,
      sessionStorage: sessionStorageData,
    };

    return event;
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppContext>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ConfigProvider
            theme={{
              token: {
                ...antdColorsConfig,
                ...antdTypographyConfig,
              },
            }}
          >
            <BrowserRouter>
              <QuestionsAnswersProvider>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </QuestionsAnswersProvider>
            </BrowserRouter>
          </ConfigProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AppContext>
  </StrictMode>
);
