import { useGetQuestionsMutation } from '@/api/services';
import { QuestionsAnswersInput } from '@/api/typesApi';
import { routes } from '@/constants';
import { useQuestionsAnswers } from '@/contexts/QuestionsAnswersContext';
import { TURN_OFF_HANDLE_ROUTING } from '@/contexts/QuestionsAnswersContext/constants';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

export default function useGetQuestionWithAnswered(fetchOnMount = true) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    questionNumber,
    selectedLanguage,
    invitationKey,
    totalQuestions,
    setQuestionNumber,
    onNextQuestion,
  } = useQuestionsAnswers();

  const curQuestion = useRef<number>(questionNumber);

  const { mutateAsync, data, isLoading } = useGetQuestionsMutation({
    onError: () => {
      curQuestion.current = curQuestion.current + 1;

      if (curQuestion.current >= totalQuestions) {
        navigate(routes.finish + `?${TURN_OFF_HANDLE_ROUTING}=1`);
        return;
      }

      debounced({
        invitation_key: invitationKey,
        language: selectedLanguage,
        question_num: curQuestion.current,
      });
    },
    onSuccess: () => {
      setQuestionNumber(curQuestion.current);
      setLoading(false);
    },
  });

  const debounced = useDebouncedCallback(
    async (input: QuestionsAnswersInput) => {
      setLoading(true);
      if (debounced.isPending()) {
        return;
      }
      await mutateAsync(input);
    },
    500
  );

  const questionName = data?.question?.name?.match(/\d+/)?.[0];
  const questionText = data?.question.text;
  const questionVideo = data?.question.video_url;
  const questionTopics = data?.question.topics.split(', ') || [];

  const isEng = selectedLanguage === 'english';

  const handleNextStep = () => {
    navigate('/answers');
  };

  useEffect(() => {
    if (!fetchOnMount) {
      return;
    }

    debounced({
      invitation_key: invitationKey,
      language: selectedLanguage,
      question_num: questionNumber,
    });
  }, [
    debounced,
    fetchOnMount,
    invitationKey,
    questionNumber,
    selectedLanguage,
  ]);

  return {
    questionName,
    questionText,
    questionVideo,
    isEng,
    isLoading: loading || isLoading || !questionName,
    selectedLanguage,
    questionTopics,
    data,
    invitationKey,
    totalQuestions,
    questionNumber,
    handleNextStep,
    onNextQuestion,
  };
}
